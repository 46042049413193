<template>
  <div class="q-pa-sm">
    <task-queues-preprocessing
        :options="options"
        @select="onRowDblClick"
    />
    <preprocessing-task-modal
        ref="preprocessingTaskModal"
        @submit="upsertItem"
    />
    </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import TaskQueuesPreprocessing from '../../components/queue/TaskQueuesPreprocessing.vue'
import PreprocessingTaskModal from "../../components/modals/PreprocessingTaskModal";

export default {
  name: 'OrderProcessingQueues',
  components: {
    TaskQueuesPreprocessing,
    PreprocessingTaskModal
  },
  computed: {
    options () {
      return {
        settings: false,
        waveSettings: true,
        type: 'preprocessing',
        title: 'Preprocessing Queues',
        service: this.$service.preprocessingQueue,
      }
    }
  },
  methods: {
    ...mapMutations([
      'setPickingQueue'
    ]),
    onRowDblClick ({ item }) {
      this.$refs.preprocessingTaskModal.open(this.options.service, item)
    },
    upsertItem () {
      this.$refs.preprocessingTaskModal.close()
    },
  }
}
</script>
