<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 40vw; overflow: visible;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ title }}
        </div>

        <q-space />

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            color="dark"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Close')"
            @click="close"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            :disable="lockedStates.includes(this.model.state)"
            :label="$t('Save')"
            @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
function getNewModel () {
  return {}
}

export default {
  name: 'TaskModal',
  emits: ['submit'],
  data () {
    return {
      isOpen: false,
      service: null,
      changedData: {},
      model: getNewModel(),
      lockedStates: [
        'complete'
      ]
    }
  },
  computed: {
    title () {
      return this.model && this.model.id
          ? `${this.$t('Task')}: ${this.model.id}`
          : this.$t('New task')
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'name',
                value: this.model.name,
                required: true,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (name) => {
                  this.model.name = name
                  this.changedData.name = name
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'priority',
                value: this.model.priority,
                required: true,
                label: this.$t('Priority'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (priority) => {
                  this.model.priority = priority
                  this.changedData.priority = priority
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: '_embedded.deliveryRequest.sender',
                value: this.model._embedded.sender,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    if (row.name) {
                      return `${row.name} (${row.id})`
                    } else {
                      return ` `
                    }
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: (sender) => {
                  this.model._embedded.sender = sender
                  this.changedData.sender = sender.id
                },
                btnStyleClasses: 'q-pl-sm'
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    close () {
      this.isOpen = false
    },
    open (service , item) {
      this.model = item
      this.service = service
      this.isOpen = true
    },
    save () {
      const data = Object.entries(this.changedData).reduce((acc, [key, value]) => {
        acc[key] = value && typeof value === 'object' && value.id
            ? value.id
            : value
        return acc
      }, {})

      data.id = undefined

      return this.service.save(data, this.model.id)
          .then(queue => {
            this.$emit('submit')
            this.changedData = {}
          })
    }
  }
}
</script>
